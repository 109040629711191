<template>
  <div>
    <br>
    <div class="row  justify-content-center">
      <div class="col-lg-1 col-11 col-md-10">
        <center><img src="img/logo.png" style="width: 80px; " ></center>
         
      </div>
      <div class="col-lg-8 col-11 col-md-10 mt-5">
          <v-card elevation="10"  >
            <v-card-text>
              <div class="row">
                <div class="col-md-5  text-center">
                  <b style="color: #266BC3;">Total de Escaneos tiempo real QR App NBA</b>
                  <br>
                  <h3 class="text-danger mt-2">{{total}}</h3>
                </div>
                <div class="col-md-3 text-center">
                  <b style="color: #266BC3;">Inicio</b>
                  <br>
                  <h6 class="text-danger mt-2 ">01 Diciembre 2022</h6>
                </div>
                <div class="col-md-3 text-center">
                  <b style="color: #266BC3;">Fin</b>
                  <br>
                  <h6 class="text-danger mt-2">01 Diciembre 2023</h6>
                </div>
                <div class="col-md-1 text-right"></div>
              </div>
            </v-card-text>
          </v-card>
          <br>
          <div class="row justify-content-center">
            <div class="col-md-4 mt-2">
              <label for="" class="text-white">Fecha inicial</label>
              <input type="date" v-model="fecha1"  class="form-control" placeholder="DD/MM/YYYY">
               <!-- <b-form-input v-model="fecha1" type="date"  elevation="16" ></b-form-input> -->
                 <small v-if="errors.includes('fecha1')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small>  
            </div>
            <div class="col-md-4 mt-2">
              <label for=""  class="text-white">Fecha final</label>
              <input type="date" v-model="fecha2"  class="form-control" placeholder="DD/MM/YYYY">
               <!-- <b-form-input v-model="fecha2"  type="date"  elevation="16" ></b-form-input> -->
                <small v-if="errors.includes('fecha2')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small> 
            </div>
            <div class="col-md-1 mt-2">
              <br>
              <center> <v-btn elevation="16" color="success" @click="traeGrafico()">Filtrar <b-spinner small v-if="loader2"></b-spinner></v-btn></center>
             
            </div>  
            <div class="col-lg-3 col-md-10 col-10  mt-2">
              <br>
              <center><v-btn elevation="16" @click="reporteExcel()">Descargar Reporte <b-spinner small v-if="loader"></b-spinner></v-btn></center>
              
            </div>     
            <div class="col-md-6">
              <br><br>
               <v-card elevation="10"  >
                <v-card-text>
                   <b style="color: #266BC3;">Actividades Escaneo QR App NBA</b>
                   <br>
                   <div id="chart">
                        <apexchart type="bar" height="980" :options="chartOptions2" ></apexchart>
                    </div>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-md-6">
              <br><br>
              <v-card elevation="10"  >
                <v-card-text>
                   <b style="color: #266BC3;">Escaneos Por Sistema Operativo </b>
                   <br>
                   <div id="chart">
                        <apexchart type="bar" height="980" :options="chartOptions1" ></apexchart>
                    </div>
                </v-card-text>
              </v-card>
            </div>    
            <div class="col-md-6">
              <br>
              <v-card elevation="10"  >
                <v-card-text>
                   <b style="color: #266BC3;">Escaneos por País </b>
                   <br>
                        <div class="table-responsive">
                            <table class="table table-hover table-striped mt-3" style="">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">País</th>
                                    <th scope="col">Escaneos</th>
                                    <th scope="col">%</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(pa, index) in arrayPais" :key="pa.id">
                                    <td>{{index + 1}}</td>
                                    <td><template v-if="!pa.pais">N/A</template><template v-else>{{pa.pais}}</template></td>
                                    <td>{{pa.total}}</td>
                                    <td>
                                      {{(pa.total*100/total).toFixed(2)}} %
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                </v-card-text>
              </v-card>
               
            </div>   
            <div class="col-md-6">
              <br>
              <v-card elevation="10"  >
                <v-card-text>
                     <b style="color: #266BC3;">Escaneos por Ciudad </b>
                     <br>
                        <div class="table-responsive">
                            <table class="table table-hover table-striped mt-3" style="">
                                <thead>
                                <tr>
                                    <th scope="col">#"</th>
                                    <th scope="col">Ciudad</th>
                                    <th scope="col">Escaneos</th>
                                    <th scope="col">%</th>
                                </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(est, index) in arrayEstado" :key="est.id">
                                    <td>{{index + 1}}</td>
                                     <td><template v-if="!est.estado">N/A</template><template v-else>{{est.estado}}</template></td>
                                    <td>{{est.total}}</td>
                                    <td>
                                      {{(est.total*100/total).toFixed(2)}} %
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                </v-card-text>
              </v-card>
               <br><br>
            </div>   
          </div>
    </div>
  </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts'
import axios from 'axios'
  export default {
    name: 'administrador',
    components:{ apexchart  },
        data () {
          return {
            total:0,
            loader:false,
            loader2:false,
            fecha1:"",
            fecha2:"",
            arrayPais:[],
            arrayEstado:[],
            errors:[],
            chartOptions1:{},
            arrayGrafico1:[],
            chartOptions2:{},
            arrayGrafico2:[]
          }
        },
        methods:{
          traeGrafico(){
            this.errors = [];
            if (!this.fecha1) {
              this.errors.push('fecha1');  
            }if (!this.fecha2) {
              this.errors.push('fecha2');  
            }if(this.errors.length == 0){
              this.traeRegistros();
            }
          },
          traeRegistros(){
            this.loader2 = true;
            var url= 'https://apinba.esmarketing.mx/api/lector/traerData?fecha1='+ this.fecha1  + '&fecha2=' +  this.fecha2
                  axios.get(url).then(
                        ({data}) => {
                          this.total = data.total;   
                          this.arrayPais = data.pais;
                          this.arrayEstado = data.estado;
                          this.arrayGrafico1 = data.grafico1;
                          this.arrayGrafico2 = data.grafico2;
                           setTimeout(() => {
                            this.grafico1();
                            this.grafico2();
                            this.loader2 = false;
                            }, 500);
                        }
                    );
          },
          reporteExcel(){
            this.loader = true;
            this.$fileDownload('https://apinba.esmarketing.mx/api/lector/reporte',
                        "reporte-App-NBA-" +  this.$getDateHour() + ".xlsx",
                ).then(
                    () => {
                        this.loader = false;
                    }
                )
          },
          grafico1(){
           this.chartOptions1 =   {
                    series: [{
                     data: this.arrayGrafico1
                    }],
                    chart: {
                    id: 'barYear',
                    height: 325,
                    width: '100%',
                    type: 'bar',
                    fontFamily: 'sans-serif',
                    toolbar: {
                        show: false,
                     tools:{
                          download: false 
                        },
                     }
                    },
                    plotOptions: {
                    bar: {
                        distributed: true,
                        horizontal: true,
                        barHeight: '65%',
                        dataLabels: {
                        position: 'bottom'
                        }
                    }
                    },
                    dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                       style: {
                    colors: ['#fff'],
                    fontFamily: 'sans-serif',
                    fontWeight: '100',
                },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex]
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                    },
                    
                    // colors: colors,
                    
                    states: {
                    normal: {
                        filter: {
                        type: 'desaturate'
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: true,
                        filter: {
                        type: 'darken',
                        value: 1
                        }
                    }
                    },
                    tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                        formatter: function (val, opts) {
                            return opts.w.globals.labels[opts.dataPointIndex]
                        }
                        }
                    }
                    },
                    yaxis: {
                    labels: {
                        show: false
                    }
                    }
            };
        
          },
          grafico2(){
           this.chartOptions2 =  {
                   series: [{
                     data: this.arrayGrafico2
                    }],
                  chart: {
                  type: 'bar',
                  height: 330,
                        toolbar: {
                        show: false,
                     tools:{
                          download: false 
                        },
                     }
                },
                xaxis: {
                  type: 'category',
                  labels: {
                    formatter: function(val) {
                      return val
                    }
                  },
                  // group: {
                  //   style: {
                  //     fontSize: '10px',
                  //     fontWeight: 700
                  //   },
                  //   groups: [
                  //     { title: '2019', cols: 4 },
                  //     { title: '2020', cols: 4 }
                  //   ]
                  // }
                },
                tooltip: {
                  x: {
                    formatter: function(val) {
                      return val
                    }  
                  }
                },
                };
          },
        },
        mounted(){
          this.traeRegistros();
        }
  }
</script>
